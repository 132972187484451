<template>
 <b-row  style="margin-top:50px;">
   <b-col cols="12" lg="1" xl="1" md="12" sm="12" xs="12"></b-col>
   <b-col cols="12" lg="10" xl="10" md="12" sm="12" xs="12">
     <b-row>
       <b-col cols="12" lg="12" xl="6" md="12" sm="12" xs="12">
         <H3 class="titulo">BLOG MIS GARABATOS</H3>          
       </b-col>
     <b-col cols="12" lg="12" xl="3" md="12" sm="12" xs="12">
          <b-form-input v-model="search" placeholder="introduzca el texto a buscar" @input="filteredText"></b-form-input>
       </b-col>       
       <b-col cols="12" lg="12" xl="3" md="12" sm="12" xs="12">
          <b-form-select v-model="selected" :options="lCategories"  value-field="name" text-field="name" @change="filteredCategories"></b-form-select>
       </b-col>              
     </b-row>
      <b-row>
         <b-col cols="12">
               <hr class="hr"/>
         </b-col>
     </b-row> 
     <b-overlay :show="show" rounded="sm">
     <b-row v-if="lBlogsFiltered.length>0">            
          <div class="col-lg-4 col-xl-4 col-md-12 col-sm-12 col-xs-12"  style="display:block;margin-bottom:15px" v-for="a in lBlogsFiltered" :key="a.id">              
            
              <b-card v-if="a.image==null" :title="a.title" img-src="https://picsum.photos/600/300/?image=25"  img-alt="Image" img-top  tag="article" style="max-width: 100%;min-width:100%;" class="mb-2" >
                    <b-card-text>
                      {{a.resume}}
                    </b-card-text>
                    <b-button variant="primary" v-show="a.texto.length>0" @click="viewActivity(a)">leer más</b-button>
              </b-card>  
              <b-card v-else :title="a.title" :img-src="a.image"  img-alt="Image" img-top  tag="article" style="max-width: 100%;min-width:100%;" class="mb-2" >
                    <b-card-text>
                      {{a.resume}}
                    </b-card-text>
                    <b-button variant="primary" v-show="a.texto.length>0" @click="viewActivity(a)">leer más</b-button>
              </b-card>                                                    
                  
          </div>
          <div class="col-md-1 col-sm-1 col-xs-12"></div>      
     </b-row>
     <b-row v-else>
            <b-col xl="4" lg="12">
              <img src="../assets/images/logonuevopeq.png"  alt="Mis Garabatos"/>   
            </b-col>
            <b-col xl="8" lg="12">
              <br/><br/><br/>
                    <h4 class="titulo">PROXIMAMENTE TENDREMOS MÁS ARTICULOS PARA LEER !!!!! </h4>
            </b-col>                    
     </b-row>
     </b-overlay>      
     <hr/>
   <br/>
    <!--  <b-row>
            <b-col xl="4" lg="12">
              <img src="../assets/images/logonuevopeq.png"  alt="Mis Garabatos"/>   
            </b-col>
            <b-col xl="8" lg="12">
              <br/><br/><br/>
                    <h4 class="titulo">PROXIMAMENTE NUESTRO BLOG </h4>
            </b-col>                    
      </b-row> -->

   </b-col>
   <b-col cols="12" lg="10" xl="10" md="12" sm="12" xs="12"></b-col>
 </b-row>  
</template>

<script>
import axios from 'axios'
  export default {
    data() {
      return {
         lCategories:[],
         lBlogsFiltered:[],
         lblogs:[],
         search:'',
         selected:'',
         show:true
      }
    },
    created()
        {
            this.getCategories();          
            this.listBlog();  
        },     
  methods:{
    filteredText()
      {
        console.log(this.search);
        if (this.selected=='')
          this.lBlogsFiltered=this.lBlogs;
        else
          this.lBlogsFiltered=this.lBlogs.filter(x=>x.resume.contains(this.search));
      },
    viewActivity(item)
      {
        console.log(item);
        this.$router.push({name:'BlogView',params:{article:item}});
      },
    filteredCategories()
     {       
       
      if (this.selected=='--')
        this.lBlogsFiltered=this.lBlogs;
      else
        this.lBlogsFiltered=this.lBlogs.filter(x=>x.category==this.selected);
     },
    listBlog()
      {
        let me=this;
              
        let header={"Authorization" : "Bearer " + this.$store.state.token};
        let configuration= {headers : header}; 
                  
              axios.get('api/v1/articles/web', configuration).then(function(response)
                {                                                                  
                  me.lBlogs=response.data;
                  me.lBlogsFiltered=me.lBlogs;
                  me.show=false;
                  console.log(me.lBlogs);
                }).catch(function(error){
                      console.log("ERROR :"+ error);
                });
      },     
    getCategories()
        {
            console.log('listando Categorias');
            let me=this;
            let header={"Authorization" : "Bearer " + this.$store.state.token};
            let configuration= {headers : header};        
            axios.get('/api/v1/categories',configuration).then(function(response)
                {   
                   console.log(response.data);
                    me.lCategories=response.data;
                 //   me.lCategoriesFiltered=me.lCategories;
                    
                        
                }).catch(function(error){
                        console.log(error);
                });
        }
    }
  }
</script>
<style>


</style>