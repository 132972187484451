<template>
<b-row>  
   <b-col cols="12">       
          <H3 class="titulo">NUESTRA ESCUELA</H3>
           <hr class="hr"/>    
           <br/><br/><br/><br/>
   </b-col>
   <b-col cols="12">
     <b-row >   
        
        <b-col cols="12" xl="3" lg="12"  v-for="item in school.images" :key="item.id" >
              <b-card                           
                :img-src="item.url"
                img-alt="Mis Garabatos"
                img-top
                tag="misgarabatos"    
                img-height="500px"           
                class="mb-2">
                <b-card-text>
                 <span class="texto-descripcion-little"> {{item.title}}</span>
                </b-card-text>                
            </b-card>              
           
        </b-col>   
        
     </b-row>
  </b-col>     
</b-row>

</template>

<script>
 import axios from 'axios'
  export default {
    data() {
      return {
         school:{title:'',images:[]}         
      }
    },
    created()
        {
            this.getCovers();            
        },     
    methods:{
      getCovers()
        {
            console.log('listando imagenes de la escuela');
            let me=this;
            let header={"Authorization" : "Bearer " + this.$store.state.token};
            let configuration= {headers : header};        
            axios.get('/api/v1/schools/web',configuration).then(function(response)
                {                                                           
                    me.school=response.data;
                    console.log(me.school);
                }).catch(function(error){
                        console.log(error);
                });
        }
    }
  }
</script>
<style>


</style>