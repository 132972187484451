<template>
  <b-row>    
    <b-col  cols="12">
          <H1 class="titulo">COMUNICATE CON NOSOTROS </H1>
          <hr class="hr"/>
    </b-col>
       <b-col cols="6" lg="2" xl="2" md="6" sm="6" xs="6" style="margin-bottom:15px;">      
           <a href="https://wa.me/+34615394199?text=Hola%20estariamos%20solicitando%20información%20" target="blank"> <img alt="Mis Garabatos Formulario" src="../assets/iconos/whatsapp64.png" title="Mis Garabatos Formulario" toltip="Whatsapp"/> </a>
    </b-col>
       <b-col cols="6" lg="2" xl="2" md="6" sm="6" xs="6" style="margin-bottom:5px;">      
           <a href="Tel: +34916476370" target="blank"> <img alt="Mis Garabatos Formulario" src="../assets/iconos/call64.png" title="Mis Garabatos Formulario" toltip="Telefono de contacto"/> </a>
    </b-col>           
    <b-col cols="6" lg="2" xl="2" md="6" sm="6" xs="6" style="margin-bottom:15px;">
      <a href=" javascript:void(0);" onclcol-md-12 col-sm-12 col-xs-12ick="window.open('https://www.facebook.com/Escuelainfantilmisgarabatos');" rel="nofollow" title="Facebook Mis Garabatos">
          <img alt="Mis Garabatos en facebook" src="../assets/iconos/Facebook-64.png" title="Mis Garabatos en facebook" toltip="Facebook"/>
      </a>
    </b-col>
  <b-col cols="6" lg="2" xl="2" md="6" sm="6" xs="6" style="margin-bottom:15px;">
      <a href=" javascript:void(0);" onclick="window.open('https://www.instagram.com/ei.misgarabatos/');" rel="nofollow" title="Instagram Mis Garabatos ">
          <img alt="Mis Garabatos en instagram" src="../assets/iconos/instagram64.png" title="Mis Garabatos en instagram"  toltip="Instagram"/>
      </a>
    </b-col>
      <b-col cols="6" lg="2" xl="2" md="6" sm="6" xs="6" style="margin-bottom:15px;">
      <a href=" javascript:void(0);" onclick="window.open('https://www.youtube.com/channel/UCCs-yr4LvsS97Pfty0yEmwA/featured');" rel="nofollow" title="Youtube Mis Garabatos ">
          <img alt="Mis Garabatos Youtube" src="../assets/iconos/youtube64.png" title="Ver youtube de  Mis Garabatos"  toltip="Youtube"/>
      </a>
    </b-col>
      <b-col cols="6" lg="2" xl="2" md="6" sm="6" xs="6" style="margin-bottom:15px;">      
          <img alt="Mis Garabatos Formulario" src="../assets/iconos/bloc64.png" title="Mis Garabatos Formulario" @click="form"  toltip="Forms"/>      
    </b-col>
      <b-col cols="12" lg="12" md="12" sm="12" xs="12" style="margin-bottom:15px;">        
            <br/><br/><br/><br/> 
            <img alt="Mis Garabatos" src="../assets/images/comunidaddemadrid.png" title="Mis Garabatos" class="img-responsive"/>                
      </b-col>        
</b-row> 
</template>
<script> 
  export default {
    data() {
      return {
         
      }
    },   
    methods:{
      form()
        {
             this.$router.push({ name:'Contacto',params:{menu:'Contacto'}})
        }
    }
  }
</script>