<template>
  <div id="app" class="container-fluid" >   
     <b-container fluid>       
      <NavBar/> 
      <container fluid >
          <router-view/>        
      </container>
 
      <Pie/>
     </b-container>        
  </div>       
</template>
<script>
import NavBar from '@/components/NavBar.vue'
import Pie from '@/components/Pie.vue'
export default {
    components: {  
      NavBar,
      Pie
  }
}
</script>
<style>
.titulo{
    font-family:'Comic Sans MS';
     color:#C39BD3; 
     word-spacing: 2px;    
     word-wrap:break-word;
     font-weight: bold;
     font-size:34px;
}
.hr {border: 0; height: 12px; box-shadow: inset 0 12px 12px -12px orange;}
.texto-descripcion-suave{
  font-size:26px;
  font-family:"Comic Sans MS";
  padding-left:5px;
  color:#549bb0;
}
.texto-descripcion-little-suave{
  font-size:18px;
  font-family:"Comic Sans MS";
  padding-left:5px;
  color:#549bb0;
}
.texto-pie-suave{
  font-size:20px;
  font-family:"Comic Sans MS";
  padding-left:5px;
  color:#549bb0;
}
.texto-suave{
  color:#549bb0;
  font-family:"Comic Sans MS";
}

.texto{
  color:#6666cc;
  font-family:"Comic Sans MS";
}
.texto-descripcion{
  font-size:26px;
  font-family:"Comic Sans MS";
  padding-left:5px;
  color:#6666cc;
}
.texto-descripcion-little{
  font-size:18px;
  font-family:"Comic Sans MS";
  padding-left:5px;
  color:#549bb0;
}
.texto-pie{
  font-size:20px;
  font-family:"Comic Sans MS";
  padding-left:5px;
  color:#549bb0;
}
.centrado{
  text-align: center;
}
</style>
