<template>
    <div class="container-fluid">
        <div style="width:100%;margin-top:50px;text-align:center;border-top:solid 1px black;">
           <br/><br/> 
           <b-row>
                <b-col  cols="12" xl="4" lg="4" md="4" sm="4" xs="12">
                    <span class="texto-pie"> Escuela Infantil privada en Madrid </span> <br/>
                    <span class="texto-pie"> Centro autorizado por la Comunidad de Madrid   </span>  <br/>                    
                    <span class="texto-pie"> Nº 28071701  </span>  <br/>   

                </b-col>
                <b-col  cols="12" xl="4" lg="4" md="4" sm="4" xs="12">
                    <b-link class="texto-pie" @click="AvisoLegal" target="_blank">Aviso Legal</b-link><br/>
                    <b-link class="texto-pie" @click="Cookies" target="_blank">Politica de Cookies Legal</b-link><br/>          
                    <b-link class="texto-pie" @click="Privacy" target="_blank">Politica de Privacidad</b-link><br/>
                    <b-link class="texto-pie" @click="DataProtection" target="_blank">Protección de datos</b-link><br/>              
                </b-col>                
                <b-col  cols="12" xl="4" lg="4" md="4" sm="4" xs="12">
                    <span class="texto-pie"> Escuela Infantil Mis Garabatos </span> <br/>                    
                    <span class="texto-pie"> Calle Nazaret, 5 28934 Mostoles - Madrid </span> <br/>
                    <span class="texto-pie"> Telefono 916141201 - 651672553 - 651672553 </span> <br/>
                </b-col>                
           </b-row>
           <br/>            
        </div>
    </div>
</template>

<script>
  export default {
    data() {
      return {
           
      }
    },
    methods:{

        AvisoLegal()
        {
            this.$router.push({ name:'AvisoLegal'})
        },
        DataProtection()
        {
            this.$router.push({ name:'DataProtection'})
        },
        Cookies()
        {
            this.$router.push({ name:'Cookies'})
        },
        Privacy()
        {
          this.$router.push({ name:'Privacy'})
          // alert('Quienes somos');
        }        
    }
  }
</script>

<style>

</style>