import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Quienes from '../components/Proyecto.vue'
import Contacto from '../components/Contact.vue'
import ListBlog from '../components/ListBlogs'
import Schools from '../components/Schools.vue'
import Services from '../components/Services.vue'
import Actitivities from '../components/Activities.vue'
import Block1       from '../components/Block1.vue'
import ServiceView  from '../components/ServicesView.vue'
import ActivitiesView from '../components/ActivitiesView.vue'
import BlogView from '../components/BlogView.vue'
import PreInscription from '../components/PreInscription.vue'
import Inscription from '../components/Inscription.vue'
import News from '../components/News.vue'
import Cookies from '../components/Cookies.vue'
import NewsView from '../components/NewsView.vue'
import Legal from '../components/Legal.vue'
import Privacy from '../components/Privacy.vue'
import DataProtection from '../components/DataProtection.vue'
import AvisoLegal from '../components/AvisoLegal.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    name: 'Home',
    component: Home
  },
  {
    path: '/Privacy',
    name: 'Privacy',
    component: Privacy
  },  
  {
    path: '/DataProtection',
    name: 'DataProtection',
    component: DataProtection
  },  
  {
    path: '/AvisoLegal',
    name: 'AvisoLegal',
    component: AvisoLegal
  },  
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/Inscripciones',
    name: 'Inscription',
    component: Inscription
  },
  {
    path: '/Legal',
    name: 'Legal',
    component: Legal
  },    
  {
    path: '/Noticias',
    name: 'NewsView',
    component: NewsView
  },  
  {
    path: '/Cookies',
    name: 'Cookies',
    component: Cookies
  },  
  {
    path: '/PreInscription',
    name: 'PreInscription',
    component: PreInscription
  },
  {
    path: '/Noticias',
    name: 'News',
    component: News
  },    
  {
    path: '/Proyecto',
    name: 'Quienes',
    component: Quienes
  },
  {
    path: '/Contacto',
    name: 'Contacto',
    component: Contacto
  },
  {
    path: '/ListBlog',
    name: 'ListBlog',
    component: ListBlog
  },  
  {
    path: '/Services',
    name: 'Services',
    component: Services
  },   
  {
    path: '/Actividades',
    name: 'Actitivities',
    component: Actitivities
  },   
  {
    path: '/Escuela',
    name: 'Schools',
    component: Schools
  },   
  {
    path: '/Page',
    name: 'Block1',
    component: Block1
  },   
  {
    path: '/ServiceView',
    name: 'ServiceView',
    component: ServiceView
  }, 
     
  {
    path: '/ActivitiesView',
    name: 'ActivitiesView',
    component: ActivitiesView
  },
  {
    path: '/BlogView',
    name: 'BlogView',
    component: BlogView
  }, 
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
