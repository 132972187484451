<template>
  <b-row>
      <b-col lg="12" xl="6" xs="12">
        <Carrousel/>
      </b-col>
      <b-modal id="modal-scoped" :visible="visible" title="ESCUELA INFANTIL MIS GARABATOS" title-class="titlePPrivacy" hide-footer size="xl">
          <div class="d-block text-left">
            <h3 class="titulo d-block text-center">POLITICA DE COOKIES</h3>
              <span class="texto-descripcion-little">
                  En <strong>MIS GARABATOS</strong> Utilizamos cookies propias y de terceros con fines analíticos. <br/>
                  En algunos espacios web puede haber vídeos u otros contenidos de terceros que también inserten cookies con fines 
                  publicitarios. <br/> <br/>
                  En esta página (se abre en ventana nueva) encontrarás más información. <br/>
                  Puedes aceptar todas las cookies pulsando "Aceptar" o decidir cuáles aceptas pulsando "Configurar"utiliza cookies propias para personalizar
                  (e.g., en función del navegador utilizado) y analizar su navegación, y mostrarle publicidad basada en sus intereses teniendo en cuenta el perfil realizado mediante el seguimiento de su navegación 
                  (e.g., páginas visitadas). <br/><br/>
                  Puedes consultar nuestra <strong> POLITICA DE COOKIES </strong> o bien <strong> CONFIGURAR </strong> o <strong> RECHAZAR LAS COOKIES </strong>  a tu gusto.. <br/>   
              </span>
              <b-row >
                <b-col xl="3"></b-col>
                <b-col xl="6" lg="12">
                  <b-button class="mt-3" variant="outline-success" block @click="handleOk">Aceptar Todas</b-button>                              
                </b-col>
                <b-col xl="3"></b-col>
              </b-row>
              <b-row>
                   <b-col lg="3"></b-col>
                    <b-col xl="3" lg="12">
                         <b-button class="mt-2" variant="outline-warning" block  @click="configure">Configurar</b-button>                                 
                    </b-col>
                    <b-col xl="3" lg="12">
                        <b-button class="mt-2" variant="outline-danger" block  @click="configure">Conocer Politica</b-button>                      
                    </b-col>  
                    <b-col lg="3"></b-col>                            
              </b-row>
       
          </div>                                               
      </b-modal> 
      <b-modal id="modal-notification" :visible="visibleNotification" :title="notification.title"  @ok="handleOk">
              <b-row>
                 <b-col xl="3" lg="12" >          
                    <img :src="notification.image"/>
                 </b-col>
                 <b-col xl="9" lg="12" >
                   <div v-html="notification.content"></div>
                 </b-col>
              </b-row>
      </b-modal>
      <b-col lg="12" xl="6" xs="12">
          <b-col cols="12"><Cards/></b-col>
          <b-col cols="12"><br/><br/><br/><br/></b-col>
          <b-col cols="12"> <Block1/> </b-col>
          <b-col cols="12"><br/><br/><br/><br/></b-col>
          <b-col cols="12"> <News/> </b-col> 
          <b-col cols="12"><br/><br/><br/><br/></b-col>
          <b-col cols="12"><Rrss/></b-col>
          <b-col cols="12"><br/><br/><br/><br/></b-col>
          <br/><br/><br/>
      </b-col>
  </b-row>

</template>

<script>
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue'
import Carrousel from '@/components/Carrousel.vue'
import News from '@/components/News.vue'
import Cards from '@/components/Cards.vue'
import Block1 from '@/components/Block1.vue'
import Rrss from '@/components/Rrss.vue'
import axios from 'axios'
export default {
  name: 'Home',
  components: {  
  Carrousel,
  Rrss,
  Cards,
  Block1,
  News
  },
    data() {
      return {
        visible:false,
        visibleNotification:false,
        notification:{title:'',content:'',image:''}
      }
    },  
  created() {
      if (this.$cookie.get('Mis-Garabatos')==null)      
        {
          this.visible=true;
          this.visibleNotification=false;
        }else
         {
           this.visibleNotification=false;
           this.visible=false;
         }
    },
   methods: {
     initialize()
     {
        this.getNotification();
     },     
     configure()
     {
        this.$router.push({ name:'Cookies'})
     },
     handleOk()
     {
       
       this.$cookie.set('Mis-Garabatos', 'Random value', {expires: 1, domain: 'eimisgarabatos.com'});
       console.log(this.$cookie.get("Mis-Garabatos"));
       this.visible=false;
     },
     getNotification()
     {
       let me=this;
        axios.get('/api/v1/notification/web').then(function(response)
                {
                    me.notification=response.data;
                  this.visibleNotification=true;
                }).catch(function(error){
                        console.log(error);
                });
     }
   }
}
</script>
<style>
 .titlePPrivacy{
   color:cornflowerblue;
   font-family:'Comic Sans MS';
 }
.banners{
  -webkit-box-shadow: 18px 10px 20px -1px rgba(214,211,214,0.51);
  -moz-box-shadow: 18px 10px 20px -1px rgba(214,211,214,0.51);
  box-shadow: 18px 10px 20px -1px rgba(214,211,214,0.51);
}
</style>
