<template>
 
  <b-row >
       <br/><br/><br/><br/>
    <b-col cols="12">
       <b-row>         
            <h1 style="font:italic normal bold 32px/normal 'Comic Sans MS', cursive, sans-serif; color:rgba(217,14,240,1);  word-spacing: 2px;
                   -o-text-overflow: ellipsis;text-overflow: ellipsis; text-shadow: 2px 2px 2px rgba(0,0,0,0.5) ;-webkit-transform-origin: 50% 50% 0;
                   transform-origin: 50% 50% 0;">NUESTROS SERVICIOS </h1>            
       </b-row>
        <hr class="hr"/>
    </b-col>
    <br/><br/>
    <b-row  >    
      <b-col cols="12" lg="12" xl="3" md="12" class="d-none d-sm-none d-md-block">        
        <img src="../assets/images/activity.jpg" alt="Mis Garabatos" style="width:95%;margin-top:20%;"/>
      </b-col>          
      <b-col cols="12" lg="9" xl="9" md="9">
          <b-row>
            <b-col cols="12" lg="12" xl="5" md="12"  v-for="item in lServices" :key="item.id" style="margin-bottom:35px;margin-left:20px;display:table;padding-bottom:10px;min-height:250px;" >           
              <b-row>
                  <b-col cols="12" lg="3" xl="2" sm="12" md="12">
                      <b-img  :src="item.image" width="100%" height="100%" class="img-responsive" />                        
                  </b-col>
                  <b-col cols="12" lg="8" xl="10"  sm="12" md="12">
                        <h4 class="texto-descripcion">{{item.name}}</h4>    
                      <span class="texto-descripcion-little">
                      {{item.description}}
                      </span>                                               
                  </b-col>                                                                                                                 
              </b-row>      
              <b-row>
                <b-col cols="12" sm="12" md="9"></b-col>
                <b-col cols="12" sm="12" md="3" v-show="item.content.length>0">
                    <b-button type="link" variant="warning" @click="viewActivity(item)">leer más</b-button> 
                </b-col>
              </b-row>
            </b-col>           
          </b-row>
      </b-col>             
    </b-row>
  </b-row> 
</template>

<script>
import axios from 'axios'
  export default {
    data() {
      return {
      lServices:[]
      }
    },
    created()
        {
            this.getServices();          
            
        }, 
    methods:{ 
    viewService(item)
        {
          console.log(item);
            this.$router.push({name:'ServiceView',params:{services:item}});
        },      
      getServices()
      {
       let me=this;
            
      let header={"Authorization" : "Bearer " + this.$store.state.token};
      let configuration= {headers : header}; 
                
            axios.get('/api/v1/services', configuration).then(function(response)
              {                                                                  
                me.lServices=response.data;
               
                console.log(me.lServices);
              }).catch(function(error){
                    console.log("ERROR :"+ error);
              });
      }
    }    
  }
</script>
<style>
 .raised{
		-webkit-box-shadow: 0 15px 10px -10px rgba(0, 0, 0, 0.5), 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
		-moz-box-shadow: 0 15px 10px -10px rgba(0, 0, 0, 0.5), 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
				box-shadow: 0 15px 10px -10px rgba(0, 0, 0, 0.5), 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
}  

.drop-shadow {
		position:relative;
		float:left;
		width:40%;
		padding:1em;
		margin:2em 10px 4em;
		background:#fff;
		-webkit-box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
		-moz-box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
		box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
	}
</style>