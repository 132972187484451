import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'

import '@mdi/font/css/materialdesignicons.css'
import { BootstrapVue,AvatarPlugin,BootstrapVueIcons  } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'

import 'bootstrap-vue/dist/bootstrap-vue.css'
import AnimatedVue from 'animated-vue'
import * as VueGoogleMaps from 'vue2-google-maps'
import 'froala-editor/js/plugins.pkgd.min.js';

import 'froala-editor/js/third_party/embedly.min';
import 'froala-editor/js/third_party/font_awesome.min';

import 'froala-editor/js/third_party/spell_checker.min';
import 'froala-editor/js/third_party/image_tui.min';
import VueSignaturePad from 'vue-signature-pad';

import 'froala-editor/css/froala_editor.pkgd.min.css';
import VueFroala from 'vue-froala-wysiwyg'
import AOS from 'aos'
import VueMeta from 'vue-meta'

//import { VueReCaptcha } from "vue-recaptcha-v3";
import VueCookies from 'vue-cookie'
import VueAnalytics from 'vue-analytics'


Vue.config.productionTip = false
axios.defaults.baseURL='https://heraclito-mis-garabatos.azurewebsites.net'
//axios.defaults.baseURL='https://localhost:44337'
Vue.use(BootstrapVue)
Vue.use(VueMeta,{
  refreshOnceOnNavigation:true
})
//Vue.use(VueReCaptcha,{ siteKey: "6LfgRKAaAAAAAA17noTgvVCIISHEzQk0OJE6t9qm" })
Vue.use(VueAnalytics, {
  id: 'UA-109433148-1' ,
  router,
  autoTracking: {
    pageviewTemplate (route) {
      return {
        page: route.path,
        title: document.title,
        location: window.location.href
      }
    }
  }  
})
Vue.use(AvatarPlugin)
Vue.use(AnimatedVue)
Vue.use(BootstrapVueIcons)
Vue.use(VueSignaturePad)
Vue.use(VueCookies)
Vue.use(VueFroala)
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyApDlUXk5rIk_vcB_Xo-mrfs5mbg8K5L50',
    libraries: "places" 
  }
})
 
Vue.use(AOS)
new Vue({
  router,  
  store,  
  VueFroala,
  render: h => h(App)
}).$mount('#app')
