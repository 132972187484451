<template>
<b-container fluid>   
  <b-overlay :show="show" rounded="md">         
    <b-col xl="12" lg="12" md="12" sm="12" xs="12">
      
          <b-alert show variant="warning">
              ¿Quieres conocer a Mis Garabatos?
              Si quieres conocernos mejor, completa el formulario
              y nos pondremos en contacto.           
          </b-alert>        
       
    </b-col>
     <b-col xl="12" lg="12" md="12" sm="12" xs="12" >
       <b-row>
         <b-col xl="2" lg="2" md="2" sm="12" xs="12">
            <label for="input-default" style="font-size:24px;color:#34B3F5;">Nombre:</label>
         </b-col>
         <b-col xl="10" lg="10" md="10" sm="12" xs="12">
             <b-form-input id="name" size="sm" placeholder="Introduzca su nombre y apellido" v-model="form.name" type="text" :state="requiredName"></b-form-input> 
         </b-col>         
         <b-col xl="2" lg="2" md="2" sm="12" xs="12">
            <label for="input-small" style="font-size:24px;color:#34B3F5;">Email :</label>
         </b-col>
         <b-col xl="10" lg="10" md="10" sm="12" xs="12">
             <b-form-input id="email" size="sm" placeholder="Introduzca su email "  type="email" v-model="form.email" :state="emailFormat"></b-form-input>
         </b-col>
        
         <b-col xl="2" lg="2" md="2" sm="12" xs="12">
          <label for="input-small" style="font-size:24px;color:#34B3F5;">Telefono:</label>
         </b-col>
         <b-col xl="10" lg="10" md="10" sm="12" xs="12">
              <b-form-input id="phone" size="sm" placeholder="Introduza su telefono" type="number" v-model="form.phone" :state="lengthState"></b-form-input>
         </b-col>          
      
         <b-col xl="2" lg="2" md="2" sm="12" xs="12">
              <label for="input-small" style="font-size:24px;color:#34B3F5;">Mensaje :</label>
         </b-col>
         <b-col xl="10" lg="10" md="10" sm="12" xs="12">
              <b-form-textarea id="text" placeholder="Texto libre"  rows="8" v-model="form.message"></b-form-textarea>
         </b-col>                    
       </b-row>
       <b-row>
          
          <b-col xl="12" lg="12" md="12" sm="12" xs="12">
              <br/><br/>   
              <b-form-checkbox id="checkbox-1"  v-model="status" name="checkbox-1"  value="accepted" unchecked-value="not_accepted" @change="validate" aria-describedby="input-live-help input-live-feedback">
                       He leído y acepto la <b-link  @click="legal" >política de protección de datos.</b-link>
              </b-form-checkbox>                
                  <br/><br/>   
                   <b-alert show variant="danger" v-show="gdpr">Debe aceptar la politica de privacidad </b-alert>      
          </b-col>
          
       </b-row>
       <b-row>
        
          <b-col cols="4" sm="3"> <b-button type="reset" variant="danger">Limpiar</b-button> </b-col>
          <b-col cols="4" sm="6"> </b-col>
          <b-col cols="4" sm="3"> <b-button type="send" variant="success" @click="send" :disabled="sents" > Enviar</b-button> </b-col>   
       </b-row>
       <br/><br/>
     </b-col>           
  </b-overlay>
  
</b-container>        
</template>

<script>
import axios from 'axios'
export default {
      data() {
      return {
        gdpr:false,
       status: 'not_accepted',
        form: {
          email: '',
          name: '',
          phone:'',
          message:'',         
        },  
        sent: false,
        show: false
      }
    },
    computed: {
      lengthState() {
        return this.form.phone.length ==9 ? true : false
      },
      emailFormat()
      {
        //eslint-disable-next-line
        const emailRegex =/^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
          console.log(this.form.email);
            //Se muestra un texto a modo de ejemplo, luego va a ser un icono
        if (emailRegex.test(this.form.email)) {          
            return true;
         } else {
            return false;
         }        
      },
      requiredName()
      {
        console.log('El contenido del campo name es '+this.form.name);
        return this.form.name!='' ? true:false;
      },
      sents()
      {
        if (this.emailFormat && this.lengthState && this.requiredName)
        {
          console.log('true');
         return true;
        }
        else
          {
            console.log('false');
            return false;
          }
      }
    },    
    methods: {
      validate()
      {        
        console.log("Valor del check "+this.status)
          if (this.status=="accepted")
           {              
             this.gdpr=false;
             console.log(this.gdpr);
           }else
           {
             
             this.gdpr=true;
             console.log(this.gdpr);
           }
      },
     makeToast() {
        this.$bvToast.toast('Email enviado correctamente', {
          title: `MIS GARABATOS`,
          variant: "success",
          solid: true
        })
      },
     reset()
     {
       this.form.name='';
       this.form.phone='';
       this.form.email='';
       this.form.message='';
     },   
     legal()
     {
        this.$router.push({ name:'Legal'})
     },
     send()
      {
       
        if(this.status=="not_accepted")
        {
          this.gdpr=true;

        }else
        {
          if (this.form.name=='')
             return;
          if (this.form.phone=='')
             return;
          if (this.form.email=='')
              return;

            this.show=true;
            let data = {
                name: this.form.name,
                email: this.form.email,
                phone: this.form.phone,
                message: this.form.message,
            };          
              axios.post('/api/v1/email/contact',data).then(function(response)
                      {                
                        console.log(response.data);                                
                        }).catch(function(error){
                            console.log('error '+error);
                        });
            this.show=false;
            this.makeToast();     
          }
       }
    }
}
</script>

<style>

</style>