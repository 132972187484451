<template>
  <b-row>     
      
      <b-col xl="12" lg="12" md="12" sm="12" xs="12">
        <b-alert show variant="warning">El siguiente formulario no asegura la inscripción, La inscripción siempre se debe hacer en 
            nuestra escuela. A traves de este formulario se presentan los datos para facilitar el proceso de inscripción.</b-alert>
      </b-col>
       
      <b-col cols="12"><br/><br/></b-col> 
      
        <b-col  xl="4" lg="4" md="12" sm="12" xs="12">
          <img src="../assets/images/logonuevo.jpg" alt="Mis Garabatos" style="width:90%;heigth:90%;"/>
        </b-col>
        <b-col  xl="8" lg="8" md="12" sm="12" xs="12">
          <h3>SOLICITUD DE INSCRIPCIÓN</h3><br/>
          <h5>CURSO ESCOLAR 2021-22</h5>
        </b-col>          
       <b-col  xl="12" lg="12" md="12" sm="12" xs="12" style="text-align:center;margin-bottom:20px;background-color:#E9E7E7;">
          <h4  >  DATOS DEL ALUMNO</h4>
        </b-col>           
        <b-col  xl="2" lg="2" md="12" sm="12" xs="12">
            <p>NOMBRE</p>
        </b-col>
        <b-col  xl="4" lg="4" md="12" sm="12" xs="12">
            <b-form-input id="name" size="sm" placeholder="Nombre del alumno" type="text" v-model="preInscription.student.name"></b-form-input>
        </b-col>
        <b-col  xl="2" lg="2" md="12" sm="12" xs="12">
          <p>APELLIDO</p>
        </b-col>
        <b-col  xl="4" lg="4" md="12" sm="12" xs="12">
          <b-form-input id="surname" size="sm" placeholder="Apellidos del alumno" type="text" v-model="preInscription.student.surName"></b-form-input>
        </b-col>
        <b-col  xl="2" lg="2" md="12" sm="12" xs="12">
            <p>FECHA DE NACIMIENTO</p>
        </b-col>
        <b-col  xl="4" lg="4" md="12" sm="12" xs="12">
          <b-form-input id="dateBirth" size="sm" placeholder="Fecha de Nacimiento" type="date" v-model="preInscription.student.dateBirth"></b-form-input>
        </b-col>
        <b-col  xl="2" lg="2" md="12" sm="12" xs="12">
            POBLACIÓN
        </b-col>                                           
        <b-col  xl="4" lg="4" md="12" sm="12" xs="12">
          <b-form-input id="poblation" size="sm" placeholder="Introduzca su población" type="text" v-model="preInscription.student.poblation"></b-form-input>                        
        </b-col>
        <b-col  xl="2" lg="2" md="12" sm="12" xs="12">
            DOMICILIO
        </b-col>                                           
        <b-col  xl="10" lg="10" md="12" sm="12" xs="12">
          <b-form-input id="address" size="sm" placeholder="Introduzca su domicilio" type="text" v-model="preInscription.student.address"></b-form-input>            
        </b-col>
        <b-col ><hr/></b-col>
        <b-col  xl="12" lg="12" md="12" sm="12" xs="12" style="text-align:center;margin-bottom:20px;background-color:#E9E7E7;">
          <h4>  DATOS DE LOS PADRES</h4>
        </b-col>                
        <b-col  lg="6" xl="6" xs="12" sm="12">
           <b-row>
                <b-col  xl="3" lg="3" md="4" sm="12" xs="12">
                    <p>NOMBRE MADRE</p>
                </b-col>
                <b-col  xl="9" lg="9" md="4" sm="12" xs="12">
                    <b-form-input id="nameMother" size="sm" placeholder="Introduzca nombre de la madre" type="text" v-model="preInscription.parent1.name"></b-form-input>
                </b-col>
              
                <b-col  xl="3" lg="3" md="4" sm="12" xs="12">
                  <p>TELEFONO</p>
                </b-col>
                <b-col  xl="9" lg="9" md="8" sm="12" xs="12">
                  <b-form-input id="phoneMother" size="sm" placeholder="Introduzca su teléfono" type="text" v-model="preInscription.parent1.phone"></b-form-input>
                </b-col>
                   
                <b-col  xl="3" lg="3" md="4" sm="12" xs="12">
                    <p>PROFESION</p>
                </b-col>
                <b-col  xl="9" lg="9" md="8" sm="12" xs="12">
                  <b-form-input id="profesionMother" size="sm" placeholder="Introduzca su profesión" type="text" preInscription.parent1.profession></b-form-input>
                </b-col>                                           
             
                <b-col  xl="3" lg="3" md="4" sm="12" xs="12">
                    DNI
                </b-col>                                           
                <b-col cols="9" xl="9" lg="9" md="8" sm="12" xs="12">
                  <b-form-input id="dniMother" size="sm" placeholder="Introduza su dni" type="text" v-model="preInscription.parent1.dni"></b-form-input>            
                </b-col>             
           </b-row>                                           
        </b-col>   
        <b-col  lg="6" xl="6" xs="12" sm="12">
           <b-row>
              <b-col  xl="3" lg="3" md="4" sm="12" xs="12">
                  <p>NOMBRE PADRE</p>
              </b-col>
              <b-col  xl="9" lg="9" md="8" sm="12" xs="12">
                  <b-form-input id="nameFather" size="sm" placeholder="Introduzca nombre del padre" type="text" v-model="preInscription.parent2.name"></b-form-input>
              </b-col>
              <b-col  xl="3" lg="3" md="4" sm="12" xs="12">
                <p>TELEFONO</p>
              </b-col>
              <b-col  xl="9" lg="9" md="8" sm="12" xs="12">
                <b-form-input id="phoneFather" size="sm" placeholder="Introduzca el teléfono del padre" type="text" v-model="preInscription.parent2.phone"></b-form-input>
              </b-col>     
              <b-col  xl="3" lg="3" md="4" sm="12" xs="12">
                  <p>PROFESION</p>
              </b-col>
              <b-col  xl="9" lg="9" md="8" sm="12" xs="12">
                <b-form-input id="profesionFather" size="sm" placeholder="Introduzca su profesión" type="text" v-model="preInscription.parent2.profession"></b-form-input>
              </b-col>
              <b-col  xl="3" lg="3" md="4" sm="12" xs="12">
                  DNI
              </b-col>                                           
              <b-col  xl="9" lg="9" md="8" sm="12" xs="12">
                <b-form-input id="dniFather" size="sm" placeholder="Introduzca su dni" type="text" v-model="preInscription.parent2.dni"></b-form-input>            
              </b-col>                 
           </b-row>                                                    
        </b-col>
        <b-col ><hr/></b-col>
        <b-col  xl="12" lg="12" md="12" sm="12" xs="12" style="text-align:center;margin-bottom:20px;background-color:#E9E7E7;">
          <h4>DATOS MÉDICOS</h4>         
        </b-col>
        <b-col  xl="4" lg="4" md="6" sm="12" xs="12">
            <p>ALERGIA ALGÚN MEDICAMENTO</p>
        </b-col>
        <b-col  xl="8" lg="8" md="6" sm="12" xs="12">
          <b-form-textarea
                id="txtMedicamento"               
                placeholder="Alergía medicamentos..."
                v-model="preInscription.alergic.medicine"
                rows="3"
                max-rows="6">
          </b-form-textarea>           
        </b-col>
        <b-col  xl="4" lg="4" md="6" sm="12" xs="12">
            <p>ALERGIA A TIPOS DE ALIMENTOS</p>
        </b-col>
        <b-col  xl="8" lg="8" md="6" sm="12" xs="12">
          <b-form-textarea
                id="txtAlimentos"
                v-model="preInscription.alergic.foods"
                placeholder="Alergía alimentos..."
                rows="3"
                max-rows="6">
          </b-form-textarea>           
        </b-col>  
        <b-col  xl="4" lg="4" md="6" sm="12" xs="12">
            <p>RESTO DE ALERGIAS</p>
        </b-col>
        <b-col  xl="8" lg="8" md="6" sm="12" xs="12">
          <b-form-textarea
                id="txtRestoAlergias"
                v-model="preInscription.alergic.rest"
                placeholder="Resto de alergias..."
                rows="3"
                max-rows="6">
          </b-form-textarea>           
        </b-col>
        <b-col  xl="4" lg="4" md="6" sm="12" xs="12">
            <p>ENFERMEDADES GRAVES</p>
        </b-col>
        <b-col  xl="8" lg="8" md="6" sm="12" xs="12">
          <b-form-textarea
                id="txtEnfermedadesGraves"
                v-model="preInscription.alergic.diseases"
                placeholder="Enfermedades Graves..."
                rows="3"
                max-rows="6">
          </b-form-textarea>           
        </b-col>
        <b-col ><hr/></b-col>
        <b-col cols="12" >
          <h6>
              NOTA: En cumplimiento de la Ley Orgánica 15/1999de Protección de Datos de Carácter Personal (LOPD) 
              la Escuela Infantil Mis Garabatos como responsable del fichero le informa, <br/>
              que los datos que se han solicitado quedarán incorporados en un fichero cuya finalidad es
              únicamente el control y organización del centro. <br/>
              Queda igualmente informado de ejercitar los derechos de acceso rectificación y oposición de sus datos personales, 
              que serán destruidos una vez cumplida la prestación contractual.<br/>
          </h6>
        </b-col>                                                  
        <b-col ><strong>Firmar aqui abajo:</strong></b-col>        
        <b-col cols="12">
           <div style="display:table;border:dotted 1px grey;width:100%;">
               <VueSignaturePad width="100%" height="200px" ref="signaturePad" />            
           </div>
        </b-col>              
        <b-col  lg="6" xl="6">  </b-col>          
        <b-col  lg="6" xl="6">  <button @click="undo">Deschacer firma</button> </b-col>     
        <b-col cols="12"><br/><hr/><br/></b-col>
          <b-col cols="4" sm="3"> <b-button type="reset" variant="danger"  size="lg" @click="cancel">Cancelar</b-button> </b-col>
          <b-col cols="4" sm="6"> </b-col>
          <b-col cols="4" sm="3"> <b-button type="send" variant="success" :disabled="status"  size="lg" @click="save">Enviar</b-button> </b-col>    
  </b-row>
</template>

<script>

export default {  
      data() {
          return {
            status:false,
            preInscription:{
                student:{
                    name:'',
                    surName:'',
                    dateBirth:'',
                    poblation:'',
                    address:''
                },
                parent1:{
                   name:'',
                   profession:'',
                   phone:'',
                   dni:''
                },
                parent2:{
                   name:'',
                   profession:'',
                   phone:'',
                   dni:''
                },
                alergic:{
                    medicine:'',
                    foods:'',
                    diseases:'',
                    rest:''
                }            
            },
            option:{
                  penColor:"rgb(0, 0, 0)",
                  backgroundColor:"rgb(255,255,255)"
                },
            disabled:false         
          }
    },
    created:{ 
    },
    methods:{
        undo() {
          this.$refs.signaturePad.undoSignature();
        },
        save() {
          const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
          console.log(isEmpty);
          console.log(data);
        },
        cancel()
        {
           // this.preInscription.student.name="";
            this.preInscription.student.surName="";
            this.preInscription.student.dateBirth="";
            this.preInscription.student.poblation="";
            this.preInscription.student.address="";
            this.preInscription.student.parent1.name="",
            this.preInscription.student.parent1.profession="";
            this.preInscription.student.parent1.phone="";
            this.preInscription.student.parent1.dni="";
            this.preInscription.student.parent2.name="";
            this.preInscription.student.parent2.profession="";
            this.preInscription.student.parent2.phone="";
            this.preInscription.student.parent2.dni="";
            this.preInscription.student.alergic.medicine="";
            this.preInscription.student.alergic.foods="";
            this.preInscription.student.alergic.diseases="";
            this.preInscription.student.alergic.rest="";          
        }
    }             
}
</script>

<style>
  #draw-canvas {
    border: 2px dotted #CCCCCC;
    border-radius: 5px;
    cursor: crosshair;
    width: 100%;
    height: 160px;;
  }

  #draw-dataUrl {
    width: 100%;
  }
</style>