<template>
<b-col lg="12" xl="12" md="12">
   <b-overlay :show="show" rounded="lg" opacity="0.6">
       <template #overlay>
        <div v-if="processing" class="text-center p-4 bg-primary text-light rounded">
            <b-icon icon="cloud-download" font-scale="4"></b-icon>
            <div class="mb-3">cargando información...</div>
            <b-progress min="1" max="20" :value="counter"  variant="success" height="3px" class="mx-n4 rounded-0"></b-progress>
          </div>         
       </template>
   <b-carousel id="carousel-fade" style="text-shadow: 0px 0px 2px #000;border-radius:9px;" :interval="8000"  fade indicators >          
    <b-carousel-slide class="d-block img-fluid w-100" v-for="item in lCovers" :key="item.id" :caption="item.title">
        <template v-slot:img>
                <img
                class="d-block class-name"
                width="90%"
                height="100%"      
                :src="item.image"
                style="color:item.colorTitle"
                alt="image slot"/>
        </template>
    </b-carousel-slide>     
  </b-carousel> 
   </b-overlay>
</b-col>

</template>

<script>
 import axios from 'axios'  
  export default {
    data() {
      return {
         lCovers:[],
         show:true,
        processing: true,
        counter: 1,
        interval: null         
      }
    },
    beforeDestroy() {
      this.clearInterval()
    },    
    created()
        {
          this.initialize();
              
        },     
    methods:{
       clearInterval() {
        if (this.interval) {
          clearInterval(this.interval)
          this.interval = null
        }
      },
      initialize()
      {
        this.getCovers();       
        this.counter = 1      
        // Simulate an async request
        this.clearInterval()
        this.interval = setInterval(() => {
        if (this.counter < 20) {
            this.counter = this.counter + 1
        } else {
            this.clearInterval()
            this.$nextTick(() => {
              this.show = this.processing = false
            })
        }
       }, 350)
      },
      getCovers()
        {
            console.log('listando portadas');
            let me=this;
              
            axios.get('/api/v1/covers/web').then(function(response)
                {                                                           
                  me.lCovers=response.data;
                  me.lCoversFilters=me.lCovers;                  
                  this.processing = false  
                  this.show=false;
                }).catch(function(error){
                        console.log(error);
                });
        }
    }
  }
</script>
<style>


</style>