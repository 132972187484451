<template>
<b-col class="container-fluid">  
    <b-row>
        <b-col xl="1" ></b-col>
        <b-col xl="10" lg="12" md="12" sm="12" xs="12">
            <b-row>
                <b-col cols="12" lg="6" xl="6" md="6" sm="3"></b-col> 
                <b-col cols="12" lg="2" xl="2" md="2" sm="3"> <b-button type="link" variant="warning" @click="back">Volver a Blogs</b-button> </b-col>                           
            </b-row>
        </b-col>
        <b-col cols="2"></b-col>      
    </b-row>
    <b-row>
        <b-col lg="12" xl="12" md="12" sm="12" >
              <hr class="hr"/>
        </b-col>
    </b-row> 
    <b-row style="padding-top:50px;">
        <b-col xl="3"></b-col>
        <b-col xl="6"> <img :src="article.image" class="img-fluid" alt="Mis Garabatos blog"/> </b-col>
        <b-col xl="3"></b-col>        
    </b-row>
    <b-row style="padding-top:50px;"> 

        <b-col xl="12" class="text-center">
            <H1>{{article.title}}</H1>
        </b-col>    
    </b-row>
    <b-row>
     
            <b-col lg="12" xl="3" md="12" sm="12" > </b-col>
            <b-col lg="12" xl="6" md="12" sm="12" > <div v-html="article.texto"/>  </b-col>
            <b-col lg="12" xl="3" md="12" sm="12" >  </b-col>
      
    </b-row>

</b-col>
</template>

<script>
export default {
   data() {
      return {
          article:{id:0,categoryId:'',title:'',dateAdd:'',texto:'',resume:'',dateFrom:'',dateTo:'',userAdd:'',user:'',category:'',image:''}
      }
    },
    created()
        {
          this.getServices();            
        }, 
    methods:{
        back()
        {
            this.$router.push({name:'ListBlog'});     
        },
        getServices()
        {
             console.log('viendo el blog ' + this.$route.params.article);
             let art=this.$route.params.article;
             if (art!=null)
                 {
                     this.article.id=art.id;
                     this.article.categoryId=art.categoryId;
                     this.article.title=art.title;
                     this.article.dateAdd=art.dateAdd;
                     this.article.texto=art.texto;
                     this.article.resume=art.resume;
                     this.article.dateFrom=art.dateFrom;
                     this.article.dateTo=art.dateTo;
                     this.article.userAdd=art.userAdd;
                     this.article.user=art.user;
                     this.article.category=art.category;
                     this.article.image=art.image;
                       console.log('el articulo ' +  this.article.title);
                 }   
        }
    }
}
</script>

<style>

</style>