<template>
 <b-row>    
      <b-col cols="12">
        <H4 class="titulo">CONOCENOS...</H4>
        <hr class="hr"/>
      </b-col>
      <b-col cols="12">
        <b-row>
            <b-col cols="12" xl="5" lg="12" md="12" sm="12" xs="12">
              <br/>
              <img src="../assets/images/nino-juguete.jpg" alt="Mis Garabatos" style="width:80%; height:70%"/>
            </b-col>
            <b-col cols="12" xl="7" lg="12" md="12" sm="12" xs="12">
                <span class="texto-descripcion-little">
                  <br/>
                    Somos un Centro donde escuchamos y donde intentamos ayudar en la labor diaria del cuidado y educación de vuestros hijos. 
                    Pero es también nuestro objetivo, el ayudarlos a SER, a ser padres y madres, a ayudaros a levantaros y animaros cuando sintáis que esta aventura se torna difícil.                    
                    <br/><br/>
                </span>   
            </b-col>           
        </b-row>             
      </b-col>   
 </b-row>
</template>

<script>

  export default {
    data() {
      return {
    
      }
    },
    created()
        {
            this.initizaled();          
            
        }, 
    methods:{
      initialized()
      {
            
      }
    }    
  }
</script>