<template>
     <b-col cols="12">
        
        <div style="width:100%;">
           <img src="../assets/iconos/home-map-marker.png"/> <span style="font-size:18px;color:#34B3F5;">C/ Nazaret Nº 5  Móstoles - Madrid 28936  </span> 
        </div>   
        <br/>           
        <div >
          <a href="tel: +34916476370" ><img src="../assets/iconos/phone.png"/> <span style="font-size:18px;color:#34B3F5;">91 6476370</span> </a>
        </div>     
        <br/>      
        <div >
         <a href="https://wa.me/+34615394199?text=Hola%20estariamos%20solicitando%20información%20"  style="color:#34B3F5;" targe="blank"><img src="../assets/iconos/whatsapp.png" alg="Whatsapp"/><span style="font-size:18px;color:#34B3F5;">615394199</span></a>
        </div>   
        <br/>      
        <div >
          <a href="mailto: escuelainfantilmisgarabatos@htomail.com" > <img src="../assets/iconos/at.png"/> <span style="font-size:18px;color:#34B3F5;">escuelainfantilmisgarabatos@hotmail.com</span></a>
        </div>          
        <br/><br/>         
     </b-col>
      
</template>

<script>
export default {

}
</script>

<style>

</style>
<style>
a:link, a:visited, a:active {
    text-decoration:none;
}
i {
    display: inline-block;
    height: 100px;
    width: 100px;  
}
</style>