<template>
   <b-container fluid >
      <b-row>
       <b-col cols="12">
          <h1 class="titulo">CERCA DE TI </h1>
          <hr class="hr"/>
      </b-col>       
      </b-row>
      <b-row>
      
       <b-col lg="10" xl="10" md="12" sm="12" xs="12">
                <div >
                      <gmap-map :center="center" :zoom="16" style="width:100%;  height: 600px;" >
                          <gmap-marker :key="index" v-for="(m, index) in markers" :position="m.position"  @click="center=m.position"></gmap-marker>
                      </gmap-map>
                  </div>  
                   <br/><br/>
       </b-col>
      
       <b-col lg="12" xl="6" md="12" sm="12" xs="12" >
           <h3 class="titulo">NUESTROS DATOS</h3>
          <hr class="hr"/>
          <DondeEstamos/>
       </b-col>
       <b-col lg="12" xl="6" md="12" sm="12" xs="12" >
         <h3 class="titulo">ESCRIBENOS </h3>
          <hr class="hr"/>            
            <FormContact/>
       </b-col>    


      </b-row>
 </b-container>

</template>

<script>
import FormContact from '@/components/FormContact.vue'
import DondeEstamos from '@/components/DondeEstamos'
export default {
  name: "Contact",
 components: {  
            FormContact,
            DondeEstamos
       },  
  data() {      
    return {
      // default to montreal to keep it simple
      // change this to whatever makes sense
      center: { lat:40.32974, lng: -3.85396 },
      markers: [{
          id:0,
          position: {lat:40.32974, lng: -3.85396 },
          title: "Escuela Infantil Mis Garabatos  Mostoles - Madrid"
        }],
      places: [],
      currentPlace: null
    };
  },

  mounted() {
  //  this.geolocate();
    console.log(this.$root);
    console.log(this.$route.params.menu);
   


  },

  methods: {

  }
};
</script>

<style>

</style>