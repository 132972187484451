<template>
<b-container fluid>
    <b-row>
        <b-col lg="1" xl="1"></b-col>
        <b-col lg="11" xl="11">
              <br/>
                <b-row style="padding-top:150px;">
                      <b-col xl="4" lg="12">
                        <img src="../assets/images/logonuevopeq.png"  alt="Mis Garabatos"/>   
                      </b-col>
                      <b-col xl="8" lg="12">                      
                              <h4 class="titulo">
                                PROXIMAMENTE PODRAS PRE-INSCRIBIRTE EN NUESTRA WEB </h4>
                             <h6 class="texto-descripcion">Estamos preparando nuestro formulario electronico para que puedas completarlo desde 
                                    nuestra web y cuando vengas tenerlo preparado...
                                    Pero tenemos habilitado nuestro formulario de contacto o nuestros datos con el podras ponerte en contacto con nosotros.. <a href="/Contacto" >Contactar</a>
                               </h6> 
                      </b-col>                    
                </b-row>

        </b-col>
        <b-col lg="1" xl="1"></b-col>
    </b-row>
    
</b-container>
</template>
<script>
//import PreInscription from '@/components/PreInscription.vue'
export default {
   components: {  
 // PreInscription  
  },
    data() {
      return {
        visible:false
      }
    }  
}
</script>