<template>
<b-container fluid>
     <b-overlay :show="show" rounded="lg" opacity="0.6">
       <template #overlay>
        <div v-if="processing" class="text-center p-4 bg-primary text-light rounded">
            <b-icon icon="cloud-download" font-scale="4"></b-icon>
            <div class="mb-3">cargando noticas de mis garabatos...</div>
            <b-progress min="1" max="20" :value="counter"  variant="success" height="3px" class="mx-n4 rounded-0"></b-progress>
          </div>         
       </template>
          <b-row>  
              <b-col cols="12">
                <H3 class="titulo">NOTICIAS</H3>
                <hr class="hr"/>
              </b-col>
          </b-row>
            <b-row> 
              <b-col  lg="1" xl="1"></b-col>     
                <b-col  lg="10" xl="10" v-show="lNews.length>0">
                  <b-carousel  v-model="slide" :interval="8000" controls indicators  background="white" img-width="1024" img-height="250"  @sliding-start="onSlideStart" @sliding-end="onSlideEnd">      
                      <b-carousel-slide  v-for="item in lNews" :key="item.id" >            
                          <template #img v-if="item.resume==''">
                            <b-row>
                              <b-col  xl="12" lg="12" md="12" sm="12" xs="12">                      
                                    <img class="d-block img-fluid w-75" :src="item.image" alt="image slot" @click="navigate(item)" >
                              </b-col>               
                            </b-row>
                          </template>        
                          <template #img v-else>
                            <b-row>
                              <b-col  xl="12" lg="12" md="12" sm="12" xs="12">
                                  <img class="d-block img-fluid w-75" :src="item.image" alt="image slot" @click="navigate(item)" >
                              </b-col>                              
                              <b-col  xl="12" lg="12" md="12" sm="12" xs="12">
                                <h3>{{item.title}}</h3>
                                <div v-html="item.resume"></div>
                              </b-col>
                            </b-row>
                          </template>                   
                      </b-carousel-slide>
                  </b-carousel>
                </b-col>
                <b-col cols="10" lg="10" xl="10" v-show="lNews.length==0">                        
                            
                            <br/>
                            <b-row>
                                  <b-col xl="4" lg="6">
                                    <img src="../assets/images/logonuevopeq.png"  alt="Mis Garabatos"/>   
                                  </b-col>
                                  <b-col xl="8" lg="6">
                                    <br/><br/><br/>
                                          <h4 class="alert-heading">NOTICIAS DE LA ESCUELA MIS GARABATOS</h4>
                                  </b-col>                    
                            </b-row>
                        
                </b-col>
                <b-col cols="1" lg="1" xl="1"></b-col>
            </b-row>
   </b-overlay>
</b-container>
</template>

<script>
import axios from 'axios'
  export default {   
    data() {      
      return {
        lNews:[],      
        sliding: null,
        slide: 0,
        show:true,
        processing: true,
        counter: 1,
        interval:null 
      }
    },    
  created()
        {
            this.initialize();            
        },     
  methods:{  
        navigate(item)
        {
            console.log(item.texto);
            if (item.texto!=null)
              this.$router.push({name:'NewsView',params:{news:item}});
        },
        clearInterval() {
            if (this.interval) {
                  clearInterval(this.interval)
            this.interval = null
          }
      },        
        initialize()
          {
            this.getNews();       
            this.counter = 1      
            // Simulate an async request
            this.clearInterval()
            this.interval = setInterval(() => {
            if (this.counter < 20) {
                this.counter = this.counter + 1
            } else {
                this.clearInterval()
                this.$nextTick(() => {
                  this.show = this.processing = false
                })
            }
          }, 350)
          },        
        getNews()
        {             
            let me=this;
            let header={"Authorization" : "Bearer " + this.$store.state.token};  
            let configuration= {headers : header};        
            axios.get('/api/v1/news/web',configuration).then(function(response)
                {                                                           
                    me.lNews=response.data;
                    this.processing = false  
                    this.show=false;                                            
                }).catch(function(error){
                        console.log(error);
                });        
          
        }
       
    }    
    
  }
</script>
<style>
.carousel-indicators li {
  background-color: black;
}

.carousel-inner, .carousel-item { 
  height: 100%
}

.background {
  background-color: white;
}
</style>