<template>
<b-col class="container-fluid">
    <b-row>
        <b-col xl="1" ></b-col>
        <b-col xl="10" lg="12" md="12" sm="12" xs="12">
            <b-row>
                <b-col cols="12" lg="6" xl="6" md="6" sm="3"></b-col> 
                <b-col cols="12" lg="2" xl="2" md="2" sm="3"> <b-button type="link" variant="warning" @click="back">Volver</b-button> </b-col>                           
            </b-row>
        </b-col>
        <b-col cols="2"></b-col>      
    </b-row>
    <b-row>       
        <b-col lg="12" xl="12" md="12" sm="12">                                
            <b-row>             
                <b-col cols="2"></b-col>
                <b-col cols="8">
                    <H1 class="titulo mx-auto">Política de Privacidad y Cookies</H1><br/><br/>
                    <div class="texto-descripcion-little">
                        <strong>DATOS IDENTIFICATIVOS</strong><br/><br/>
                        <span>Este sitio web es propiedad de Escuela Infantil Mis Garabatos,  centro educativo homologado por la Comunidad de Madrid, autorizado con orden 3085/2010 y con domicilio en C/ Camino de Leganés 50, de Móstoles (Madrid), que pone en conocimiento de los usuarios del sitio su política de privacidad y condiciones de uso a los efectos previstos por la ley.

                        Escuela Infantil Mis Garabatos se reserva el derecho de modificar las condiciones de uso y la política de privacidad en cualquier momento, para mantenerlas acordes a la legislación vigente o adaptarla a las novedades jurisprudenciales.

                        Para cualquier duda o consulta que tengas al respecto, puedes ponerte en contacto con nosotros a través del correo electrónico escuelainfantilmisgarabatos@hotmail.com</span>
                        <br/><br/>
                        <strong>POLÍTICA DE PRIVACIDAD</strong><br/><br/>
                        <p>Esta web se ha adecuado a las exigencias de la Ley Orgánica 15/1999, de 13 de diciembre, de Protección de Datos de Carácter Personal (LOPD). Cumple también con el Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo de 27 de abril de 2016 relativo a la protección de las personas físicas (RGPD), así como con la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y Comercio Electrónico (LSSICE o LSSI). Tiene implementadas todas las medidas de seguridad, técnicas y organizativas necesarias para garantizar y proteger la confidencialidad, integridad y disponibilidad de los datos introducidos.</p>

                        <p><strong>Tratamiento de los datos personales</strong></p>
                        <p>Los usuarios que faciliten datos personales en nuestro sitio web a través de los formularios, consienten a la incorporación de los mismos al fichero automatizado de clientes de Escuela Infantil Mis Garabatos. En Escuela Infantil Mis Garabatos adoptamos las medidas técnicas y administrativas necesarias para asegurarnos de que tus datos personales son tratados en todo momento de forma confidencial y segura, de acuerdo con lo dispuesto por la ley. Escuela Infantil Mis Garabatos en ningún caso venderá, cederá o alquilará tus datos de carácter a terceros, excepto por obligación legal.</p>

                        <p>Los datos recogidos serán usados únicamente para ofrecer la información solicitada y para mantener la comunicación comercial con el usuario sobre nuestros servicios y actividades, pudiendo el titular de los datos solicitar en cualquier momento el cese de estas comunicaciones.</p>

                        <p>La base legal para el tratamiento de los datos es tu consentimiento. Con la confirmación del envío de datos a través de los formularios de la web, consientes expresamente al tratamiento de éstos por parte de Escuela Infantil Mis Garabatos para las finalidades anteriormente indicadas.

                        Los datos personales que nos proporciones se conservarán mientras se mantenga la relación comercial o hasta que solicites su supresión. Los conservaremos debidamente guardados y protegidos, cumpliendo con la normativa vigente en cada momento.</p>
                        <br/><br/>
                        <p><strong>Tus derechos</strong><p>
                        <p>Puedes ejercer tus derechos de acceso, rectificación, supresión, portabilidad y oposición mediante un escrito dirigido a Escuela Infantil Mis Garabatos, C/ Nazaret Nº 5 28936 - Móstoles(Madrid) o enviando una solicitud por correo electrónico a  escuelainfantilmisgarabatos@hotmail.com

                        Tienes derecho a retirar el consentimiento prestado en cualquier momento. La retirada del consentimiento no afectará a la licitud del tratamiento efectuado antes de la retirada del consentimiento.

                        Como usuario, garantizas la veracidad de los datos aportados y te comprometes a comunicar cualquier cambio que se produzca en los mismos.</p><p>  No seremos en ningún caso responsables de la licitud, veracidad y exactitud de los datos que nos suministras.

                        En caso de que se pudiera ocasionar cualquier perjuicio, directo o indirecto a un tercero o al responsable de esta web porque el usuario facilite datos falsos, incompletos o inexactos, el único responsable sería el propio usuario.</p>                      
                        <br/>
                        <p><strong>USO DE COOKIES</strong></p>
                        <p>Escuela Infantil Mis Garabatos utiliza cookies el sitio web con fines técnicos y para mejorar la experiencia de navegación del usuario. Para cumplir con la legislación vigente, te pedimos tu permiso para gestionarlas. Si decides no autorizar el tratamiento indicándonos tu no conformidad, solo emplearemos las cookies que son imprescindibles para que la navegación por nuestra web sea posible. Sin embargo, no se almacenará ninguna cookie. Siempre que sigas navegando por nuestro sitio web sin denegar su autorización, implicará que aceptas su uso.</p>

                        <p>Una cookie es un pequeño archivo que se envía al navegador y que se asocia con un usuario anónimo, registrando el uso que se hace del sitio.</p>
                        <p> No proporcionan datos personales por sí mismas, ni permiten extraer información de su disco duro. Las cookies ayudan a mejorar la experiencia de navegación en la web y a optimizar los servicios y contenidos que ofrecemos.</p>

                        <p><strong>¿Qué cookies usamos en nuestra web?</strong></p>
                        <p>En esta web utilizamos cookies técnicas, que son aquellas imprescindibles y estrictamente necesarias para su correcto funcionamiento y para la utilización de las diferentes opciones y servicios que ofrece. Para ofrecer funciones de redes sociales y analizar el tráfico con el objetivo de mejorar nuestros servicios, también usamos cookies analíticas de terceros. Se trata de las cookies de Google Analytics que recopilan información en forma anónima, incluyendo el número de visitantes al sitio, cómo han llegado al mismo y las páginas que visitó mientras navegaba en nuestro sitio web.</p>
                        <br/>
                       <p><strong> Cómo  desactivar las  cookies</strong></p>
                       <p> La mayoría de los navegadores aceptan la utilización de cookies de forma automática pero puedes modiﬁcar la conﬁguración de acceso a la página web, eliminar las cookies o impedir que se registren en tu equipo en cualquier momento, modificando la conﬁguración de tu navegador. Si bien la parametrización de cada navegador es diferente, es habitual que la configuración de las cookies se realice en el menú de “Preferencias” o “Herramientas”. </p>
                       <p>Para más detalles, consulta el menú “Ayuda” de tu navegador.</p>
                        <p>Ten en cuenta que si rechazas o borras las cookies de navegación, algunas características de las páginas no estarán operativas, no podremos ofrecerte servicios personalizados y cada vez que vayas a navegar por nuestra web tendremos que solicitar de nuevo tu autorización para el uso de cookies.</p>
                        <br/><br/>                    
                        <p><strong>AVISO LEGAL Y CONDICIONES DE USO</strong></p>
                        <p>Escuela Infantil Mis Garabatos es titular de todos los derechos de propiedad intelectual de este sitio web, así como de los elementos contenidos en el mismo, teniendo todos los derechos reservados. Todos los contenidos que se incluyen en este sitio web se facilitan a efectos exclusivamente informativos, de modo que en ningún caso pueden servir de base para recursos o reclamaciones ni constituir fuente de derechos.</p>
                        <p>El usuario se compromete a hacer un uso adecuado y responsable de los contenidos ofrecidos por el sitio web de Escuela Infantil Mis Garabatos.  Igualmente se obliga al uso exclusivo de la web y de sus contenidos para fines lícitos que no infrinjan la legalidad vigente o puedan resultar lesivos de los derechos legítimos de Escuela Infantil Mis Garabatos o de terceros, causando cualquier daño o perjuicio de forma directa o indirecta.</p>
                        <p>Asimismo, en virtud de lo dispuesto en la Ley de Propiedad Intelectual, el usuario se compromete a abstenerse de reproducir, copiar, distribuir, transformar o modificar los contenidos del sitio a menos que se cuente con la autorización del titular de los correspondientes derechos o que ello resulte legalmente permitido.</p>
                        <p>Escuela Infantil Mis Garabatos no se hace responsable, en ningún caso, de los daños o perjuicios de cualquier naturaleza que pudiera ocasionar el uso del sitio web, a título enunciativo: falta de disponibilidad del sitio, o la transmisión de virus o programas lesivos en los contenidos, a pesar de haber adoptado todas las medidas tecnológicas necesarias para evitarlo.
                        La relación entre Escuela Infantil Mis Garabatos y el usuario de la web se regirá por la normativa española vigente y cualquier controversia que pudiera aparecer, queda sometida a la competencia de los Tribunales de Madrid.</p>
                    </div>
                    <hr class="hr"/>
                </b-col>     
                <b-col cols="2"></b-col>                     
                <br/> <br/><br/> <br/>
            </b-row>                                               
        </b-col>
       
    </b-row>

</b-col>
</template>
<script>
export default {
   data() {
      return {
          legal:{id:0,text:''}
      }
    },
    created()
        {
          this.getServices();            
        }, 
    methods:{
        back()
        {
            this.$router.push({ name:'Contacto'})
        },
        getServices()
        {
                         
        }
    }
}
</script>

<style>

</style>