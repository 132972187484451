<template>
  <b-col class="container-fluid">
    <b-row>
        <b-col xl="1" ></b-col>
        <b-col xl="10" lg="12" md="12" sm="12" xs="12">
            <b-row>
                <b-col cols="12" lg="6" xl="6" md="6" sm="3"></b-col> 
                <b-col cols="12" lg="2" xl="2" md="2" sm="3"> <b-button type="link" variant="warning" @click="back">Volver a Pagina Principal</b-button> </b-col>                           
            </b-row>
        </b-col>
        <b-col cols="2"></b-col>      
    </b-row>
    <b-row>
         <b-col xl="1" ></b-col>
        <b-col lg="10" xl="10" md="10" sm="10">                    
            <b-row>             
                <b-col cols="12">
                    <H3 class="titulo mx-auto">{{news.title}}</H3>
                    <hr class="hr"/>
                </b-col>                          
                <br/> <br/><br/> <br/>
            </b-row>           
            <b-row>               
                <b-col cols="12"> <div v-html="news.texto"/>  </b-col>
            </b-row>                         
        </b-col>
         <b-col xl="1" ></b-col>       
    </b-row>

</b-col>
</template>

<script>
export default {
  data() {
      return {
          news:{id:0,title:'',resume:'',texto:'',image:'',dateFrom: new Date().toISOString().substr(0, 10),dateTo: new Date().toISOString().substr(0, 10)},
      }
    },
    created()
        {
               this.getNew();            
        }, 
    methods:{
        back()
        {
            this.$router.push({name:'Home'});     
        },
        getNew()
        {
             console.log(this.$route.params.news);
             let art=this.$route.params.news;
             if (art!=null)
                 {
                     this.news.id=art.id;                     
                     this.news.title=art.title;
                     this.news.dateFrom=art.dateFrom;
                     this.news.dateTo=art.dateTo;                     
                     this.news.texto=art.texto;
                     this.news.resume=art.resume;                     
                 }   
        }
    }
}
</script>

<style>

</style>