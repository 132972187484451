<template>
<b-row>
  <b-col cols="12" lg="12" xl="12">  
        <H1 class="titulo">TÚ ILUSION, TU CAMINO</H1>      
  </b-col>
  <b-col cols="12" lg="12" xl="12">
    <br/>
      <span class="texto-descripcion-little">                 
        Mis Garabatos está formado por un grupo de profesionales  con una clara vocación hacia la infancia, formadas en Disciplina Positiva y Pedagogías activas. <br/>           
        Contamos con todas las titulaciones  exigidas por la Consejería de Educación de la Comunidad de Madrid, para desarrollar la potencialidad de tu hija/o.
        <br/><br/>   
      </span>  
  </b-col>   
     
</b-row>
</template>

<script>

export default {
name: 'Cards',
  components: {  
  }
}
</script>