<template>
  <b-row>
      <b-col cols="2">
        <span ></span>
      </b-col>
      <b-col cols="8">                    
         <b-row>
            <b-col cols="6">
                <H3 class="titulo">{{service.name}}</H3>          
            </b-col> 
            <b-col cols="2">
                <b-button type="link" variant="warning" @click="back">Volver a Servicios</b-button> 
            </b-col>                                                   
             <hr class="hr"/>
             <br/> <br/><br/> <br/>
          </b-row>                                        
      <div v-html="service.content"/>              
      </b-col>
      <b-col cols="2"><span></span></b-col>

  </b-row>
</template>

<script>
export default {
   data() {
      return {
      service:{id:0,image:'',name:'',description:'',content:'',title:''}
      }
    },
    created()
        {
            this.getServices();
            
        }, 
    methods:{
        back()
        {
            this.$router.push({name:'Services'});     
        },
        getServices()
        {
             console.log(this.$route.params.services);
             let serv=this.$route.params.services;
             if (serv!=null)
                 {
                     this.service.image=serv.image;
                     this.service.title=serv.title;
                     this.service.name=serv.name;
                     this.service.description=serv.description;
                     this.service.content=serv.content
                 }   
        }
    }
}
</script>

<style>

</style>