<template>
<b-row >   
  <b-row>  
      <b-col cols="1" lg="1" xl="1" md="12" sm="12" xs="12"></b-col>
      <b-col cols="12" xl="3" lg="3" md="12" sm="12" xs="121">
         <br/><br/>
          <img src="../assets/images/FreepickMisGarabatos2.jpg" alg="Mis Garabatos" style="width:90%;height:60%;margin-top:15%;"/>
      </b-col>
       <b-col cols="12" xl="8" lg="8" md="12" sm="12" xs="12">
          <H3 class="titulo">{{project.weTittle}}</H3>          
          <hr class="hr"/>
          <br/>  <br/>
          <froalaView v-model="project.weContent"></froalaView>
       </b-col>       
  </b-row>
 
  <b-row>
    <b-col cols="1" lg="1" xl="1" md="12" sm="12" xs="12" ></b-col>  
    <b-col cols="11" xl="8" lg="8" md="12" sm="12" xs="12">
      <H3 class="titulo">{{project.whoIsTittle}}</H3>          
      <hr class="hr"/>
      <br/>  <br/> 
      <froalaView  v-model="project.whoIsContent"></froalaView  >
    </b-col>     
    <b-col cols="11" xl="3" lg="3" md="12" sm="11" xs="11">     
        <img src="../assets/images/FreepickMisGarabatos.jpg" alg="Mis Garabatos" style="width:90%;height:60%;margin-top:20%;"/>
    </b-col>       
    <b-col cols="2" lg="2" xl="2" md="12" sm="12" xs="12" ></b-col>
  </b-row>
</b-row>      
</template>

<script>
import axios from 'axios'
export default {
   data() {
      return {
       project:{id:1,whoIsTittle:'',whoIsContent:'',weTittle:'',weContent :''}
      }
    },
    created()
        {
          this.getProject();
        }, 
    methods:{
        getProject()
        {
          let me=this;                
          let header={"Authorization" : "Bearer " + this.$store.state.token};
          let configuration= {headers : header};
            axios.get('/api/v1/schools/project/1', configuration).then(function(response)
              {
                me.project=response.data;
              }).catch(function(error){
                    console.log("ERROR :"+ error);
              });
        }
    }
}
</script>

<style>

</style>