<template>
  <b-container fluid>
    <b-row>
        <b-col lg="1" xl="1"></b-col>
        <b-col lg="11" xl="11">
              <br/>
                <b-row style="padding-top:150px;">
                      <b-col xl="4" lg="12">
                        <img src="../assets/images/logonuevopeq.png"  alt="Mis Garabatos"/>   
                      </b-col>
                      <b-col xl="8" lg="12">                      
                            <span>
                                <strong>Cookies e identificación de usuarios</strong>                                
                                   _gac: Google Analitycs 
                                   _gid : Google Analitycs
                            </span>
                      </b-col>                    
                </b-row>

        </b-col>
        <b-col lg="1" xl="1"></b-col>
    </b-row>
    
</b-container>
</template>

<script>
export default {

}
</script>

<style>

</style>