<template>

  <b-navbar toggleable="xl"  >
    <b-navbar-brand href="#">
      <img src="../assets/images/logonuevopeq.png"  alt="Mis Garabatos"/>
     
    </b-navbar-brand>   
    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
    <span class="d-block d-sm-none" style="font-family:'Comic Sans MS';font-size:28px; color:#7E57C2;">MIS GARABATOS</span> 

      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto">
          <b-nav-item href="/" class="fontRed" > 
            <p style="color:#F57C00;">HOME </p>
          </b-nav-item>
          <b-nav-item class="fontRed" @click="quienes"> 
            <p style="color:#689F38;">  CONOCENOS  </p>
          </b-nav-item>
          <b-nav-item class="fontRed" @click="activities">
            <p style="color:#29B6F6;">  ACTIVIDADES </p>
          </b-nav-item>
          <b-nav-item class="fontRed" @click="services">
            <p style="color:#BA68C8;"> SERVICIOS</p>
          </b-nav-item>
          <b-nav-item class="fontRed" @click="listBlog">
            <p style="color:#EC407A;"> NUESTRO BLOG</p>
          </b-nav-item>
          <b-nav-item class="fontRed" @click="escuela">
            <p style="color:#42A5F5;"> NUESTRO ESPACIO</p>
          </b-nav-item>
          <b-nav-item class="fontRed" @click="preinscription">
            <p style="color:#D35400;"> PREINSCRIPCIONES</p>
          </b-nav-item>
          <b-nav-item class="fontRed" @click="contact">
            <p style="color:#4DB6AC;"> CONTACTO</p>
          </b-nav-item>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>  
 

</template>

<script>
  export default {
    data() {
      return {
           
      }
    },
    methods:{

        listBlog()
        {
            this.$router.push({ name:'ListBlog'})
        },
        services()
        {
            this.$router.push({ name:'Services'})
        },
        activities()
        {
            this.$router.push({ name:'Actitivities'})
        },
        quienes()
        {
          this.$router.push({ name:'Quienes'})
          // alert('Quienes somos');
        },
        preinscription()
        {
          this.$router.push({ name:'Inscription'})
          // alert('Quienes somos');
        },        
        contact()
        {
          this.$router.push({ name:'Contacto'})
        },
        escuela()
        {
          this.$router.push({name:'Schools'})
        }        
    }
  }
</script>
<style>
.tittle{
  margin-left:350px;
  font-family:'Comic Sans MS';
  font-size:75px;
  color:rgb(136, 9, 255);  
}

.fontRed{
  font-family: "Comic Sans MS";
  font-size: 22px;
  color: brown;
   }
</style>